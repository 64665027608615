import styled from "styled-components";

const ProjectBase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem 0rem 0rem;
`;
export default ProjectBase;
