import styled from "styled-components";

const InformationDescription = styled.p`
  font-size: 2rem;
  line-height: 1.5;
  margin: 5rem 1rem;
  height: 100%;
  width: 100vw;
  color: ${(props) => props.theme.color};

  @media only screen and (max-width: 375px) {
    font-size: 1.5rem;
    width: 90%;
    border-bottom: none;
  }
`;
export default InformationDescription;
