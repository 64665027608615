import React from "react";
import ProjectImage from "../ProjectImage";
import ProjectTitle from "../ProjectTitle";
import ProjectDescription from "../ProjectDescription";
import webshop from "../../assets/OldToolsImages/oldtools1.jpg";
import ProjectBase from "../ProjectBase";
import PreviewTextBox from "../PreviewTextBox";
import Arrow from "../Arrow";
import BoxOne from "../BoxOne";
import TagBase from "../TagBase";
import Tag from "../Tag";

function ProjectOldToolsWebshop() {
  return (
    <ProjectBase>
      <ProjectImage src={webshop} alt="alt" />

      <PreviewTextBox>
        <BoxOne>
          <ProjectTitle>A small webshop</ProjectTitle>
          <ProjectDescription>
            A simple webshop made with HTML, CSS & JavaSript.
          </ProjectDescription>
          <Arrow />
        </BoxOne>
        <TagBase>
          <Tag>Frontend</Tag>
          <Tag>JavaScript</Tag>
          <Tag>CSS</Tag>
        </TagBase>
      </PreviewTextBox>
    </ProjectBase>
  );
}

export default ProjectOldToolsWebshop;
