import styled from "styled-components";

const HeadingBox = styled.div`
  width: 40%;
  /* margin: 0rem 0rem 5rem 0rem; */
  @media screen and (max-width: 375px) {
    width: 99%;
  }

  @media screen and (max-width: 414px) {
    width: 99%;
  }
`;
export default HeadingBox;
