import styled from "styled-components";

const AnchorBase = styled.a`
  border: 1px solid;
  font-size: 0.8rem;
  border-radius: 0;
  padding: 0.5rem;
  text-decoration: none;
  margin: 1rem;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  transition: 0.2s ease;
  display: flex;

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.background};
    transform: scale(1.05);
  }
`;
export default AnchorBase;
