import styled from "styled-components";

const SiteNavigationBase = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.background};
  position: sticky;
  top: 0;

  ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0.25rem 0.5rem;
  }

  li {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.color};
    font-size: 0.7rem;
    transition: 0.2s ease-in;
  }

  a:hover {
    transition: 0.2s ease-in;
    font-style: italic;
    margin-bottom: 0.1rem;
  }
`;

export default SiteNavigationBase;
