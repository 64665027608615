import styled from "styled-components";

const LargeImage = styled.img`
  width: 98%;
  margin: 2rem 0rem 1rem 0rem;

  @media screen and (max-width: 375px) {
    width: 90%;
    height: 50%;
    object-fit: contain;
  }

  @media screen and (max-width: 414px) {
    width: 95%;
    height: 50%;
    object-fit: contain;
  }
`;
export default LargeImage;
