import React from "react";
import ProjectTitle from "../ProjectTitle";
import HomeSectionBase from "../HomeSectionBase";

function HomeSection() {
  return (
    <HomeSectionBase>
      <ProjectTitle>
        My name is Emil <br /> and I am a creative technologist.
        <br /> Scroll down to see some of my projects ↴
      </ProjectTitle>
    </HomeSectionBase>
  );
}

export default HomeSection;
