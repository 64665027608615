import React from 'react';
import ArrowBox from '../ArrowBox';

function Arrow() {
  return(
    <ArrowBox>
      ↳
    </ArrowBox>
  )
}

export default Arrow;