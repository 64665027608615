import styled from 'styled-components';

const ProjectStack = styled.div`
  font-size: 1rem;
  transition: 4.5s ease;

  ul {
    list-style-type: none;
    padding: 0;
  }  
`;
export default ProjectStack;