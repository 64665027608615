import styled from "styled-components";

const FooterBase = styled.footer`
  padding: 1rem;
  border-top: 1px solid;
  color: ${(props) => props.theme.color};
  transition: 1s ease;
`;

export default FooterBase;
