import React from "react";
import SiteNavigation from "../../components/SiteNavigation";
import ProjectBase from "../../components/ProjectBase";
import ProjectImage from "../../components/ProjectImage";
import ProjectTitle from "../../components/ProjectTitle";
import ProjectDescription from "../../components/ProjectDescription";
import loginPage from "../../assets/BurgerBarImages/loginPage.jpg";
import loginPage2 from "../../assets/BurgerBarImages/loginPage2.jpg";
import signup from "../../assets/BurgerBarImages/signup.jpg";
import burgersPage from "../../assets/BurgerBarImages/burgersPage.jpg";
import drinksPage from "../../assets/BurgerBarImages/drinksPage.jpg";
import addOnsPage from "../../assets/BurgerBarImages/addOnsPage.jpg";
import cartPage from "../../assets/BurgerBarImages/cartPage.jpg";
import firestore from "../../assets/BurgerBarImages/firestore.png";
import realtimedatabase from "../../assets/BurgerBarImages/realtimedatabase.png";
import pickup from "../../assets/BurgerBarImages/pickup.jpg";
import orders from "../../assets/BurgerBarImages/orders.jpg";

import TextBox from "../../components/TextBox";
import ProjectStack from "../../components/ProjectStack";
import HeadingBox from "../../components/HeadingBox";
import Main from "../../components/Main";
import ImageBox from "../../components/ImageBox";
import LargeImage from "../../components/Images/LargeImage";
import SmallImage from "../../components/Images/SmallImage";
import AnchorBase from "../../components/AnchorBase";

function BurgerBar() {
  return (
    <Main>
      <SiteNavigation />
      <ProjectBase>
        <ProjectImage src={loginPage} />
        <TextBox>
          <HeadingBox>
            <ProjectTitle>Burger Bar</ProjectTitle>
            <ProjectStack>
              <ul>
                <li>Next JS</li>
                <li>React</li>
                <li>API</li>
                <li>Styled components</li>
                <li>Google Firebase</li>
                <li>Firestore / Realtime database</li>
              </ul>
            </ProjectStack>
          </HeadingBox>
          <ProjectDescription>
            This project takes on a ficticious burger bar. It is an ordering
            system wich sends and retrieves data to and from Firestore and the
            Realtime database. It is split into three parts. <br />
            <br />
            1. A menu screen from where the customers can place an order.
            <br />
            2. A screen where customers can see orders that are ready for pickup
            or being prepared.
            <br />
            3. A screen for the kitchen staff to manage incoming orders.
          </ProjectDescription>
        </TextBox>
        <div
          style={{
            height: "100%",
            width: "100vw",
            margin: "2.5rem 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <AnchorBase
            href="https://github.com/emilschultz/burger-bar"
            target="_blank"
          >
            <div style={{ marginRight: ".5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
              </svg>
            </div>
            Click here for Github repository
          </AnchorBase>
        </div>
        <ProjectTitle
          style={{ fontSize: "2rem", margin: "5rem 0rem 0rem 2rem" }}
        >
          Authentication
        </ProjectTitle>

        <ImageBox>
          <SmallImage src={signup} />
          <SmallImage src={loginPage2} />
        </ImageBox>

        <ProjectTitle
          style={{ fontSize: "2rem", margin: "3rem 0rem 0rem 2rem" }}
        >
          Ordering interface
        </ProjectTitle>

        <LargeImage src={burgersPage} />

        <ImageBox>
          <SmallImage src={drinksPage} />
          <SmallImage src={addOnsPage} />
        </ImageBox>

        <LargeImage src={cartPage} />

        <ProjectTitle
          style={{ fontSize: "2rem", margin: "3rem 0rem 0rem 2rem" }}
        >
          Firestore and Realtime Database
        </ProjectTitle>
        <ImageBox>
          <SmallImage src={firestore} />
          <SmallImage src={realtimedatabase} />
        </ImageBox>

        <ProjectTitle
          style={{ fontSize: "2rem", margin: "3rem 0rem 0rem 2rem" }}
        >
          Pickup screen
        </ProjectTitle>
        <LargeImage src={pickup} />
        <ProjectTitle
          style={{ fontSize: "2rem", margin: "3rem 0rem 0rem 2rem" }}
        >
          Orders (kitchen screen)
        </ProjectTitle>
        <LargeImage src={orders} />
      </ProjectBase>
    </Main>
  );
}

export default BurgerBar;
