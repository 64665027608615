import styled from "styled-components";

const BoxOne = styled.div`
  width: 50%;

  @media screen and (max-width: 375px) {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.color};
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 414px) {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.color};
    padding-bottom: 1rem;
  }
`;

export default BoxOne;
