import styled from "styled-components";

const Main = styled.main`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 2s ease-out;

  @media only screen and (max-width: 375px) {
    margin-right: 0;
  }
`;
export default Main;
