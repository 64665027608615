import styled from "styled-components";

const ProjectImage = styled.img`
  width: 98vw;
  height: 85vh;
  object-fit: cover;
  object-position: center;

  @media screen and (max-width: 375px) {
    width: 90%;
    height: 50%;
    object-fit: contain;
  }

  @media screen and (max-width: 414px) {
    width: 95%;
    height: 50%;
    object-fit: contain;
  }
`;
export default ProjectImage;
