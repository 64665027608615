import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import GlobalStyle from "./components/GlobalStyle";
import ProjectsContainer from "./containers/ProjectsContainer";
import InformationContainer from "./containers/InformationContainer";
import CraterMap from "./containers/CraterMap";
import OldToolsWebshop from "./containers/OldToolsWebshop";
import Animations from "./containers/Animations";
import TekstilRevolutionen from "./containers/TekstilRevolutionen";
import BurgerBar from "./containers/BurgerBar";

import FooterBase from "./components/FooterBase";
import ButtonBase from "./components/ButtonBase";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./components/Themes";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const location = useLocation();
  const [theme, setTheme] = useState("light");

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyle />

      {/* <Router> */}
      <ScrollToTop />
      <AnimatePresence>
        <Switch location={location} key={location.key}>
          <Route path="/information" component={InformationContainer} />
          <Route path="/crater-map" component={CraterMap} />
          <Route path="/a-small-webshop" component={OldToolsWebshop} />
          <Route path="/animations" component={Animations} />
          <Route path="/burger-bar" component={BurgerBar} />
          <Route path="/tekstilrevolutionen" component={TekstilRevolutionen} />

          <Route path="/" component={ProjectsContainer} />
        </Switch>
      </AnimatePresence>
      {/* </Router> */}
      <FooterBase>
        <ButtonBase onClick={() => themeToggler()}> Light / Dark </ButtonBase>
      </FooterBase>
    </ThemeProvider>
  );
}

export default App;
