import React from "react";
import SiteNavigation from "../../components/SiteNavigation";
import ProjectBase from "../../components/ProjectBase";
import ProjectImage from "../../components/ProjectImage";
import ProjectTitle from "../../components/ProjectTitle";
import ProjectDescription from "../../components/ProjectDescription";
import cover from "../../assets/TekRevImages/cover.png";
import TextBox from "../../components/TextBox";
import LargeImage from "../../components/Images/LargeImage";
import ProjectStack from "../../components/ProjectStack";
import HeadingBox from "../../components/HeadingBox";
import Main from "../../components/Main";
import Elements from "../../assets/TekRevImages/elements.png";
import Gif from "../../assets/TekRevImages/Final2.gif";
import MainLogo from "../../assets/TekRevImages/mainlogo.png";
import DualLogo from "../../assets/TekRevImages/dual-logo.png";
import Colors from "../../assets/TekRevImages/colors.png";
import Web from "../../assets/TekRevImages/web.png";
import Stationary from "../../assets/TekRevImages/stationary.png";
import Blog from "../../assets/TekRevImages/blog.png";
import Letter from "../../assets/TekRevImages/letter.png";
import Contact from "../../assets/TekRevImages/contact.png";
import Mobile from "../../assets/TekRevImages/mobile1.png";
import Facebook from "../../assets/TekRevImages/facebook.png";
import Banners from "../../assets/TekRevImages/banners.png";

function TekstilRevolutionen() {
  return (
    <Main>
      <SiteNavigation />
      <ProjectBase>
        <ProjectImage style={{ objectPosition: "top" }} src={cover} />
        <TextBox>
          <HeadingBox>
            <ProjectTitle>TekstilRevolutionen</ProjectTitle>
            <ProjectStack>
              <ul>
                <li>Visual identity</li>
                <li>Logo design</li>
                <li>Print</li>
              </ul>
            </ProjectStack>
          </HeadingBox>
          <ProjectDescription>
            Tekstilrevolutionen is a platform for responsible production of
            textile and textile entrepreneurship. Tekstilrevolutionen does
            podcasts, talks and keeps a blog - all about the textile industry.
            Tekstilrevolutionen is working on creating a debate, and on pushing
            things forward, in order to give people more insight to the industry
            and inspire towards a more responsible and sustainable development.
          </ProjectDescription>
        </TextBox>
        <LargeImage src={Elements} />
        <img src={Gif} style={{ height: "65vh", margin: "2rem" }} />
        <img
          src={MainLogo}
          style={{
            height: "55vh",
            margin: "10rem",
          }}
        />
        <LargeImage src={DualLogo} />
        <TextBox style={{ margin: "6rem" }}>
          <ProjectDescription>
            A complex industry, complex topics, complex solutions and a target
            audience who take their time to learn about the subject. This turned
            into a logo which takes time to decrypt, and an information-heavy
            identity.
          </ProjectDescription>
        </TextBox>
        <LargeImage style={{ width: "75%" }} src={Colors} />
        <LargeImage style={{ width: "75%" }} src={Web} />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "5rem",
          }}
        >
          <img
            src={Stationary}
            style={{
              width: "33%",
              margin: ".5rem",
            }}
          />

          <img
            src={Blog}
            style={{
              width: "33%",
              margin: ".5rem",
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <img
            src={Contact}
            style={{
              width: "33%",
              margin: ".5rem",
            }}
          />
          <img
            src={Letter}
            style={{
              width: "33%",
              margin: ".5rem",
            }}
          />
        </div>
        <TextBox style={{ margin: "6rem" }}>
          <ProjectDescription>
            It is not about lifting fingers and banging heads. It is about
            finding soultions. People are well aware of the fact that problems
            exist. We are just the ones being critical to them.
          </ProjectDescription>
        </TextBox>

        <LargeImage style={{ width: "75%" }} src={Mobile} />
        <LargeImage style={{ width: "75%" }} src={Facebook} />
        <LargeImage
          style={{ width: "75%", marginBottom: "5rem" }}
          src={Banners}
        />
      </ProjectBase>
    </Main>
  );
}

export default TekstilRevolutionen;
