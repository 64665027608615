import styled from 'styled-components';

const ButtonBase = styled.button`
  border: 1px solid;
  border-radius: 0;
  padding: .25rem;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.color};
  transition: 1s ease;
`;
export default ButtonBase;