import styled from "styled-components";

const ProjectTitle = styled.h1`
  width: 100%;
  font-size: 3rem;
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  margin: 1rem 0 0 0;
  color: ${(props) => props.theme.color};
  transition: 1.5s ease;

  @media only screen and (max-width: 375px) {
    width: 90%;
    font-size: 2.1rem;
    margin: 0;
  }

  @media only screen and (max-width: 414px) {
    width: 90%;
    font-size: 2.1rem;
    margin: 0;
  }
`;
export default ProjectTitle;
