import styled from "styled-components";

const GifLarge = styled.img`
  height: 90vh;
  margin: 2rem;

  @media screen and (max-width: 375px) {
    width: 80%;
    object-fit: contain;
    margin: 0;
  }
  @media screen and (max-width: 414px) {
    width: 80%;
    object-fit: contain;
    margin: 0;
  }
`;
export default GifLarge;
