import React from "react";
import { NavLink } from "react-router-dom";
import SiteNavigationBase from "../../components/SiteNavigationBase";

function SiteNavigation() {
  return (
    <>
      <SiteNavigationBase as="nav">
        <ul>
          <li>
            <NavLink to="/">Home / Projects</NavLink>
          </li>
          <li>
            <NavLink to="/information">Information</NavLink>
          </li>
        </ul>
      </SiteNavigationBase>
    </>
  );
}

export default SiteNavigation;
