import styled from "styled-components";

const GridSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 2fr));
  grid-row: auto;
  border-bottom: 1px solid ${(props) => props.theme.color};
`;
export default GridSection;
