import React from "react";
import ProjectImage from "../ProjectImage";
import ProjectTitle from "../ProjectTitle";
import ProjectDescription from "../ProjectDescription";
import ShipOne from "../../assets//AnimationsImages/ship1.jpg";
import ProjectBase from "../ProjectBase";
import PreviewTextBox from "../PreviewTextBox";
import Arrow from "../Arrow";
import BoxOne from "../BoxOne";
import TagBase from "../TagBase";
import Tag from "../Tag";

function ProjectAnimations() {
  return (
    <>
      <ProjectBase>
        <ProjectImage src={ShipOne} alt="#" />

        <PreviewTextBox>
          <BoxOne>
            <ProjectTitle>Animations</ProjectTitle>
            <ProjectDescription>
              CSS animations triggered with JavaScript
            </ProjectDescription>
            <Arrow />
          </BoxOne>
          <TagBase>
            <Tag>Frontend</Tag>
            <Tag>CSS Animations</Tag>
          </TagBase>
        </PreviewTextBox>
      </ProjectBase>
    </>
  );
}

export default ProjectAnimations;
