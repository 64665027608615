import React from "react";
import ProjectImage from "../ProjectImage";
import ProjectTitle from "../ProjectTitle";
import ProjectDescription from "../ProjectDescription";
import coverTwo from "../../assets/TekRevImages/cover2.png";
import ProjectBase from "../ProjectBase";
import PreviewTextBox from "../PreviewTextBox";
import Arrow from "../Arrow";
import BoxOne from "../BoxOne";
import TagBase from "../TagBase";
import Tag from "../Tag";

function ProjectTekstilRevolutionen() {
  return (
    <>
      <ProjectBase>
        <ProjectImage src={coverTwo} alt="#" />

        <PreviewTextBox>
          <BoxOne>
            <ProjectTitle>Tekstil Revolutionen</ProjectTitle>
            <ProjectDescription>
              Visual identity for sustainable think tank.
            </ProjectDescription>
            <Arrow />
          </BoxOne>
          <TagBase>
            <Tag>Design</Tag>
            <Tag>Visual identity</Tag>
            <Tag>Graphic design</Tag>
          </TagBase>
        </PreviewTextBox>
      </ProjectBase>
    </>
  );
}

export default ProjectTekstilRevolutionen;
