import React from "react";
import ProjectImage from "../ProjectImage";
import ProjectTitle from "../ProjectTitle";
import ProjectDescription from "../ProjectDescription";
import burgersPage from "../../assets/BurgerBarImages/burgersPage.jpg";
import ProjectBase from "../ProjectBase";
import PreviewTextBox from "../PreviewTextBox";
import Arrow from "../Arrow";
import BoxOne from "../BoxOne";
import TagBase from "../TagBase";
import Tag from "../Tag";

function ProjectBurgerBar() {
  return (
    <>
      <ProjectBase>
        <ProjectImage src={burgersPage} alt="A mockup of a web page" />

        <PreviewTextBox>
          <BoxOne>
            <ProjectTitle>Burger Bar</ProjectTitle>
            <ProjectDescription>
              Database driven application - More than just displaying
              information.
            </ProjectDescription>
            <Arrow />
          </BoxOne>
          <TagBase>
            <Tag>Frontend</Tag>
            <Tag>Next JS</Tag>
            <Tag>Cloud Storage</Tag>
          </TagBase>
        </PreviewTextBox>
      </ProjectBase>
    </>
  );
}

export default ProjectBurgerBar;
