import styled from "styled-components";

const InformationContainerTitle = styled.h1`
  font-size: 2rem;
  letter-spacing: 1.4px;
  margin-bottom: 5rem;
  padding: 1rem;

  width: 100%;
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  margin: 1rem 0 0 0;
  color: ${(props) => props.theme.color};
  transition: 1.5s ease;

  @media only screen and (max-width: 375px) {
    width: 90%;
    height: 100vh;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
  }

  @media only screen and (max-width: 414px) {
    width: 90%;
    height: 100vh;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
  }
`;

export default InformationContainerTitle;
