import styled from "styled-components";

const Heading = styled.h1`
  font-size: 1.2rem;
  margin: 1rem;
  font-family: "ivypresto-display", serif;
  font-weight: 100;
  color: ${(props) => props.theme.color};

  @media only screen and (max-width: 375px) {
    font-size: 0.5rem;
  }
`;
export default Heading;
