import styled from "styled-components";

const ProjectTextBox = styled.div`
  width: 98%;
  display: flex;
  color: ${(props) => props.theme.color};
  margin: 0 1rem;

  @media screen and (max-width: 375px) {
    width: 90%;
  }

  @media screen and (max-width: 414px) {
    width: 90%;
  }
`;
export default ProjectTextBox;
