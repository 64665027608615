import styled from "styled-components";

const ProjectDescription = styled.p`
  font-size: 1rem;
  width: 60%;
  color: ${(props) => props.theme.color};
  transition: 3s ease;

  @media screen and (max-width: 375px) {
    width: 90%;
  }

  @media screen and (max-width: 414px) {
    width: 90%;
  }
`;
export default ProjectDescription;
