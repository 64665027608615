import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.color};

  &:hover {
    font-style: italic;
  }
`;
export default Link;
