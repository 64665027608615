import styled from "styled-components";

const HomeSectionBase = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: ${(props) => props.theme.backgound};
  color: ${(props) => props.theme.color};

  /* @media screen and (max-width: 320px) {
    flex-direction: column;
    width: 90%;
    padding: 0;
    
  } */
  @media only screen and (max-width: 375px) {
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: 414px) {
    padding: 0;
    margin: 0;
  }
`;

export default HomeSectionBase;
