import styled from "styled-components";

const Tag = styled.p`
  font-size: 0.8rem;
  margin-left: 1rem;
  border: 0.5px solid ${(props) => props.theme.color};
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.color};

  @media screen and (max-width: 375px) {
    display: none;
  }
  @media screen and (max-width: 414px) {
    display: none;
  }
`;
export default Tag;
