import React from "react";
import SiteNavigation from "../../components/SiteNavigation";
import Main from "../../components/Main";
import HomeSection from "../../components/HomeSection";
import ProjectCraterMap from "../../components/ProjectCraterMap";
import ProjectOldToolsWebshop from "../../components/ProjectOldToolsWebshop";
import ProjectAnimations from "../../components/ProjectAnimations";
import ProjectTekstilRevolutionen from "../../components/ProjectTekstilRevolutionen";
import ProjectBurgerBar from "../../components/ProjectBurgerBar";

import { motion } from "framer-motion";

import { NavLink } from "react-router-dom";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 1.2 },
  },
  exit: {
    opacity: 1,
    transition: { ease: "easeIn" },
  },
};

function ProjectsContainer() {
  return (
    <Main as="main">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <SiteNavigation />
        <HomeSection />

        <NavLink style={{ textDecoration: "none" }} to="/crater-map">
          <ProjectCraterMap />
        </NavLink>

        <NavLink style={{ textDecoration: "none" }} to="/a-small-webshop">
          <ProjectOldToolsWebshop />
        </NavLink>

        <NavLink style={{ textDecoration: "none" }} to="/animations">
          <ProjectAnimations />
        </NavLink>

        <NavLink style={{ textDecoration: "none" }} to="/burger-bar">
          <ProjectBurgerBar />
        </NavLink>

        <NavLink style={{ textDecoration: "none" }} to="/tekstilrevolutionen">
          <ProjectTekstilRevolutionen />
        </NavLink>
      </motion.div>
    </Main>
  );
}

export default ProjectsContainer;
