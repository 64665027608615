import React from "react";
import ProjectImage from "../ProjectImage";
import ProjectTitle from "../ProjectTitle";
import ProjectDescription from "../ProjectDescription";
import crater from "../../assets/crater.jpeg";
import ProjectBase from "../ProjectBase";
import PreviewTextBox from "../PreviewTextBox";
import Arrow from "../Arrow";
import TagBase from "../TagBase";
import Tag from "../Tag";
import BoxOne from "../BoxOne";

function ProjectCraterMap() {
  return (
    <ProjectBase>
      <ProjectImage style={{ objectPosition: "left" }} src={crater} alt="#" />

      <PreviewTextBox>
        <BoxOne>
          <ProjectTitle>Crater map</ProjectTitle>
          <ProjectDescription>
            Visualizing content with APIs.
          </ProjectDescription>

          <Arrow />
        </BoxOne>
        <TagBase>
          <Tag>Frontend</Tag>
          <Tag>React</Tag>
          <Tag>API</Tag>
        </TagBase>
      </PreviewTextBox>
    </ProjectBase>
  );
}

export default ProjectCraterMap;
