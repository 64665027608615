import styled from "styled-components";

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  object-fit: contain;

  @media screen and (max-width: 375px) {
    width: 90%;
    height: 50%;
    object-fit: contain;
  }

  @media screen and (max-width: 414px) {
    width: 95%;
    height: 50%;
    object-fit: contain;
  }
`;
export default ImageBox;
