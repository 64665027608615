import styled from "styled-components";

const TagBase = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.color};
  /* margin: 2rem 1rem 0 1rem; */
  margin: 1rem 0 0 0;

  @media screen and (max-width: 375px) {
    flex-wrap: wrap;
    width: 0%;
  }
  @media screen and (max-width: 414px) {
    flex-wrap: wrap;
    width: 0%;
  }
`;
export default TagBase;
