import React from "react";
import SiteNavigation from "../../components/SiteNavigation";
import Heading from "../../components/Heading";
import PreviewTextBox from "../../components/PreviewTextBox";
import ProjectTitle from "../../components/ProjectTitle";
import GridSection from "../../components/GridSection";
import InformationDescription from "../../components/InformationDescription";
import InformationContainerTitle from "../../components/InformationContainerTitle";
import Link from "../../components/Link";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 1.2 },
  },
  exit: {
    opacity: 1,
    transition: { ease: "easeIn" },
  },
};

function InformationContainer() {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <SiteNavigation />
      <PreviewTextBox>
        <InformationContainerTitle>
          My name is Emil and I am a creative technologist. I love design,
          JavaScript, exploring different web technologies and creating digital
          experiences. <br />
          <br />
          At the moment I am working for Amedia in Norway, on developing a
          reusable design system for their 90+ news publications, using Web
          Components and TypeScript (among other things).
        </InformationContainerTitle>
      </PreviewTextBox>
      <GridSection>
        <Heading style={{ fontSize: "2rem", margin: "5rem 2rem" }}>
          Contact
        </Heading>
        <InformationDescription>
          (+47) 48 44 63 52
          <br />
          emilrschultz@gmail.com
          <br />
          <Link href="https://www.linkedin.com/in/emilschultz/" target="blank">
            LinkedIn
          </Link>
          <br />
          <Link href="https://github.com/emilschultz" target="blank">
            Github
          </Link>
        </InformationDescription>
      </GridSection>
      <br />
      <GridSection>
        <Heading style={{ fontSize: "2rem", margin: "5rem 2rem" }}>
          Experience
        </Heading>
        <InformationDescription>
          Amedia <br />
          Frontend developer <br />
          2021-now <br />
          <br />
          TekstilRevolutionen <br />
          Designer <br />
          2018-2019 <br />
          <br />
          Makershirt <br />
          Student Designer <br />
          2016-2018 <br />
        </InformationDescription>
      </GridSection>
      <br />
      <GridSection style={{ borderBottom: "0px solid red" }}>
        <Heading style={{ fontSize: "2rem", margin: "5rem 1rem" }}>
          Education
        </Heading>
        <InformationDescription>
          Fagskolen Kristiania <br />
          Frontend development <br />
          2020-2021 <br />
          <br />
          KEA <br />
          Brand & Graphic design <br />
          2017-2019 <br />
          <br />
          KEA <br />
          E-design <br />
          2014-2016
        </InformationDescription>
      </GridSection>
    </motion.div>
  );
}

export default InformationContainer;
