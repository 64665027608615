import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, 
  body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 20px;
    line-height: 1.5;
}

  body {
    font-family: 'degular-text', sans-serif;
    font-weight: 300;
    font-style: normal;
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.background};

    @media only screen and (max-width: 375px) {
font-size: 10px;  
}
  }

`;

export default GlobalStyle;
